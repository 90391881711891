import {
  Form,
  Link,
  NavLink,
  useLoaderData,
  ActionFunctionArgs,
  useActionData,
} from "react-router-dom";
import { getApiHost } from "../getApiHost";
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import { ArrowUpRightIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Input } from "../atoms/Input";
import { Button } from "../atoms/Button";
import { useEffect, useRef } from "react";
export const loader = (token: string | undefined) => async () => {
  const response = await fetch(`${getApiHost()}/morrisons_price_alerts`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status === 200) {
    const { alertsWithLatestPrice } = await response.json();
    return alertsWithLatestPrice;
  }
  return [];
};

export const action =
  (token: string | undefined) =>
  async ({ params, request }: ActionFunctionArgs) => {
    const formData = await request.formData();
    const intent = formData.get("intent");
    if (intent === "delete-price-alert") {
      const priceAlertId = formData.get("price-alert-id");
      try {
        const response = await fetch(`${getApiHost()}/morrisons_price_alerts`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ priceAlertId }),
        });
        const data: { message: string } = await response.json();
        alert(data.message);
        return null;
      } catch (e) {
        console.error(e);
        return null;
      }
    }
    if (intent === "add-price-alert") {
      const productLink = formData.get("product-link");
      try {
        const response = await fetch(`${getApiHost()}/morrisons_price_alerts`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ productLink }),
        });
        const data: { message: string } = await response.json();
        alert(data.message);
        return {
          type: "ADDED_PRICE_ALERT",
        };
      } catch (e) {
        console.error(e);
        return null;
      }
    }
    return null;
  };

export function PriceAlerts() {
  const addPriceAlertFormRef = useRef<HTMLFormElement>(null);

  const actionData = useActionData() as { type: "ADDED_PRICE_ALERT" } | null;

  useEffect(() => {
    if (actionData?.type === "ADDED_PRICE_ALERT") {
      addPriceAlertFormRef?.current?.reset();
    }
  }, [actionData]);

  const priceAlerts = useLoaderData() as {
    productLink: string;
    priceAlertId: string;
    latestPrice?: {
      totalPriceInCentsWithPromotion: number | null;
      totalPriceInCentsWithoutPromotion: number;
      promotionDescription: string | null;
    };
  }[];

  const sortedPriceAlerts = priceAlerts.sort((a, b) => {
    const bValue = !b.latestPrice
      ? 2
      : b.latestPrice.totalPriceInCentsWithPromotion
      ? 1
      : 0;
    const aValue = !a.latestPrice
      ? 2
      : a.latestPrice.totalPriceInCentsWithPromotion
      ? 1
      : 0;
    return bValue - aValue;
  });

  return (
    <div className="h-full">
      <div className="text-center text-2xl p-4 font-medium">Price Alerts</div>
      <div className="py-2">
        <Form method="post" noValidate ref={addPriceAlertFormRef}>
          <Input
            type="text"
            name="product-link"
            label="Enter link"
            className="pb-2"
          />
          <Button
            name="intent"
            value="add-price-alert"
            type={"submit"}
            color={"primary"}
            size={"md"}
            text={"Track"}
            className="min-w-full"
          />
        </Form>
      </div>
      <div>
        {sortedPriceAlerts.map((alert) => (
          <div className="my-4" key={alert.priceAlertId}>
            <Card>
              {!alert.latestPrice && (
                <CardHeader className="flex flex-row justify-between">
                  <div>No price history yet</div>
                  <Form method="post">
                    <input
                      type="hidden"
                      name="price-alert-id"
                      value={alert.priceAlertId}
                    />
                    <button
                      type="submit"
                      name="intent"
                      value="delete-price-alert"
                    >
                      <TrashIcon className="size-6 inline-block ml-2 text-danger" />
                    </button>
                  </Form>
                </CardHeader>
              )}
              {alert.latestPrice && (
                <CardHeader className="flex flex-row justify-between">
                  {alert.latestPrice.promotionDescription &&
                  alert.latestPrice.totalPriceInCentsWithPromotion ? (
                    <div className="text-success">
                      {alert.latestPrice.totalPriceInCentsWithoutPromotion !==
                        alert.latestPrice.totalPriceInCentsWithPromotion && (
                        <span className="line-through">
                          {(
                            alert.latestPrice
                              .totalPriceInCentsWithoutPromotion / 100
                          ).toLocaleString("en-UK", {
                            style: "currency",
                            currency: "GBP",
                          })}
                        </span>
                      )}
                      {alert.latestPrice.totalPriceInCentsWithoutPromotion !==
                        alert.latestPrice.totalPriceInCentsWithPromotion && " "}
                      {(
                        alert.latestPrice.totalPriceInCentsWithPromotion / 100
                      ).toLocaleString("en-UK", {
                        style: "currency",
                        currency: "GBP",
                      })}
                      {" | "}
                      {alert.latestPrice.promotionDescription}
                    </div>
                  ) : (
                    <div className="text-warning">
                      {(
                        alert.latestPrice.totalPriceInCentsWithoutPromotion /
                        100
                      ).toLocaleString("en-UK", {
                        style: "currency",
                        currency: "GBP",
                      })}
                    </div>
                  )}
                  <Form method="post">
                    <input
                      type="hidden"
                      name="price-alert-id"
                      value={alert.priceAlertId}
                    />
                    <button
                      type="submit"
                      name="intent"
                      value="delete-price-alert"
                    >
                      <TrashIcon className="size-6 inline-block ml-2 text-danger" />
                    </button>
                  </Form>
                </CardHeader>
              )}
              <CardBody className="text-primary flex-row justify-between items-center">
                <NavLink to={`/price-alert/${alert.priceAlertId}`}>
                  {alert.productLink.split("/")[4].replace(/-/g, " ")}
                </NavLink>
                <Link to={alert.productLink} target="_blank">
                  <ArrowUpRightIcon className="size-6 inline-block ml-2" />
                </Link>
              </CardBody>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}
