import { ActionFunctionArgs, Form } from "react-router-dom";
import { ampli } from "../ampli";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "@nextui-org/react";
import { EmailInput } from "../molecules/EmailInput";
import { PasswordInput } from "../molecules/PasswordInput";
import { getApiHost } from "../getApiHost";
import { LoginFunction, useAuth } from "../Auth";
import { RegisterButton } from "../molecules/RegisterButton";
import { LoginButton } from "../molecules/LoginButton";
import { Features } from "../Features";
import { useGoogleOneTapLogin } from "@react-oauth/google";

export const action =
  (login: LoginFunction) =>
  async ({ params, request }: ActionFunctionArgs) => {
    const formData = await request.formData();
    const intent = formData.get("intent");
    if (intent === "register") {
      const email = formData.get("email");
      const password = formData.get("password");
      try {
        const response = await fetch(`${getApiHost()}/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        });
        if (response.status === 201) {
          ampli.registerSuccess();
          const data: { token: string } = await response.json();
          login(data.token);
          return null;
        } else {
          const data: { message: string } = await response.json();
          alert(data.message);
          ampli.registerFailed();
          return null;
        }
      } catch (e) {
        alert("Something went wrong. Please try again later");
        console.error(e);
        ampli.registerFailed();
        return null;
      }
    }
    if (intent === "login") {
      const email = formData.get("email");
      const password = formData.get("password");
      try {
        const response = await fetch(`${getApiHost()}/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        });
        if (response.status === 200) {
          const data: { token: string } = await response.json();
          ampli.loginSuccess();
          login(data.token);
          return null;
        } else {
          const data: { message: string } = await response.json();
          alert(data.message);
          ampli.loginFailed();
          return null;
        }
      } catch (e) {
        alert("Something went wrong. Please try again later");
        console.error(e);
        ampli.loginFailed();
        return null;
      }
    }
    alert("Something went wrong. Please try again later");
    return null;
  };

const REGISTER_TAB_KEY = "Register";
const LOGIN_TAB_KEY = "Login";

const validTabKeys = [REGISTER_TAB_KEY, LOGIN_TAB_KEY] as const;

export function LandingPage() {
  useEffect(() => {
    ampli.landingPageViewed();
  }, []);

  const [selectedTab, setSelectedTab] =
    useState<(typeof validTabKeys)[number]>(REGISTER_TAB_KEY);

  useEffect(() => {
    if (selectedTab === REGISTER_TAB_KEY) {
      ampli.registerPageViewed();
    }
    if (selectedTab === LOGIN_TAB_KEY) {
      ampli.loginPageViewed();
    }
  }, [selectedTab]);

  const { login } = useAuth();

  useGoogleOneTapLogin({
    use_fedcm_for_prompt: true,
    onSuccess: async (credentialResponse) => {
      try {
        const response = await fetch(`${getApiHost()}/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: credentialResponse.credential }),
        });
        if (response.status === 200) {
          ampli.loginSuccess();
          const data: { token: string } = await response.json();
          login(data.token);
          return null;
        } else if (response.status === 201) {
          ampli.registerSuccess();
          const data: { token: string } = await response.json();
          login(data.token);
          return null;
        } else {
          const data: { message: string } = await response.json();
          alert(data.message);
          ampli.registerFailed();
          return null;
        }
      } catch (e) {
        alert("Something went wrong. Please try again later");
        console.error(e);
        ampli.registerFailed();
        return null;
      }
    },
    onError: () => {
      alert("Something went wrong. Please try again later");
    },
  });

  return (
    <div className="flex-col justify-center items-center">
      <div className="text-3xl font-bold pb-5 text-center">
        Keep your grocery spend under check
      </div>
      <div className="text-xl font-semibold pb-7 text-center">
        Scan your receipts and keep track of every item ever purchased
      </div>
      <div className="max-w-sm mx-auto text-center pb-8">
        <Tabs
          variant="underlined"
          size="lg"
          selectedKey={selectedTab}
          onSelectionChange={(key) =>
            setSelectedTab(key as (typeof validTabKeys)[number])
          }
        >
          <Tab key={REGISTER_TAB_KEY} title="Register">
            <Form method="post" noValidate>
              <EmailInput />
              <PasswordInput />
              <RegisterButton />
            </Form>
          </Tab>
          <Tab key={LOGIN_TAB_KEY} title="Login">
            <Form method="post" noValidate>
              <EmailInput />
              <PasswordInput />
              <LoginButton />
            </Form>
          </Tab>
        </Tabs>
      </div>
      <div className="max-w-sm mx-auto">
        <Features />
      </div>
    </div>
  );
}
