import { ampli } from "./ampli";
import { getApiHost } from "./getApiHost";

export async function checkForVersionUpdates() {
  fetch(`${getApiHost()}/version`)
    .then((response) => {
      if (!response.ok) {
        ampli.appUpdateError();
      } else {
        response
          .json()
          .then(({ version }) => {
            const storedVersion = localStorage.getItem("version");
            if (!storedVersion) {
              localStorage.setItem("version", version);
              return;
            }
            if (storedVersion !== version) {
              ampli
                .appUpdateSuccess({
                  previousVersion: storedVersion ?? undefined,
                  newVersion: version,
                })
                .promise.then(() => {
                  localStorage.setItem("version", version);
                  window.location.reload();
                })
                .catch(() => {
                  // If the event fails to send, we still want to update the version
                  localStorage.setItem("version", version);
                  window.location.reload();
                });
            }
          })
          .catch(() => ampli.appUpdateError());
      }
    })
    .catch(() => console.error("Error fetching version"));
}
