import {
  HomeIcon as HomeOutlineIcon,
  DocumentIcon as DocumentOutlineIcon,
  UserIcon as UserOutlineIcon,
  ShoppingCartIcon as ShoppingCartOutlineIcon,
  BellIcon as BellOutlineIcon,
} from "@heroicons/react/24/outline";
import {
  HomeIcon as HomeSolidIcon,
  DocumentIcon as DocumentSolidIcon,
  UserIcon as UserSolidIcon,
  ShoppingCartIcon as ShoppingCartSolidIcon,
  BellIcon as BellSolidIcon,
} from "@heroicons/react/24/solid";

import { NavLink } from "react-router-dom";
import { useAuth } from "./Auth";
import { FloatingActionButton } from "./FloatingActionButton";
import { useEffect } from "react";
import { useState } from "react";
import { getApiHost } from "./getApiHost";

export function Footer() {
  const { user } = useAuth();

  const showShoppingList =
    false && user?.userId === "2a869a71-ffcf-4386-af78-f5a3f607a347";

  const showPriceAlerts =
    user?.userId === "2a869a71-ffcf-4386-af78-f5a3f607a347";

  const [hasUploadedReceipt, setHasUploadedReceipt] = useState(false);

  useEffect(() => {
    fetch(`${getApiHost()}/has-uploaded-receipt`, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setHasUploadedReceipt(data.hasUploadedReceipt));
  }, []);

  return (
    <>
      <FloatingActionButton showArrow={!hasUploadedReceipt} />
      <div className="flex justify-between justify-items-center pt-4">
        <NavLink to={"/home"}>
          {({ isActive }) =>
            isActive ? (
              <HomeSolidIcon className="size-6" />
            ) : (
              <HomeOutlineIcon className="size-6" />
            )
          }
        </NavLink>
        <NavLink to={"/receipts"}>
          {({ isActive }) =>
            isActive ? (
              <DocumentSolidIcon className="size-6" />
            ) : (
              <DocumentOutlineIcon className="size-6" />
            )
          }
        </NavLink>
        {showShoppingList && (
          <NavLink to={"/shopping-list"}>
            {({ isActive }) =>
              isActive ? (
                <ShoppingCartSolidIcon className="size-6" />
              ) : (
                <ShoppingCartOutlineIcon className="size-6" />
              )
            }
          </NavLink>
        )}
        {showPriceAlerts && (
          <NavLink to={"/price-alerts"}>
            {({ isActive }) =>
              isActive ? (
                <BellSolidIcon className="size-6" />
              ) : (
                <BellOutlineIcon className="size-6" />
              )
            }
          </NavLink>
        )}
        <NavLink to={"/profile"}>
          {({ isActive }) =>
            isActive ? (
              <UserSolidIcon className="size-6" />
            ) : (
              <UserOutlineIcon className="size-6" />
            )
          }
        </NavLink>
      </div>
    </>
  );
}
