import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Home, loader as homeLoader } from "./routes/Home";
import { Profile, action as profileAction } from "./routes/Profile";
import { Receipts, loader as receiptsLoader } from "./routes/Receipts";
import { AuthProvider, useAuth } from "./Auth";
import { AuthRoot } from "./AuthRoot";
import { UnAuthRoot } from "./UnAuthRoot";
import {
  Receipt,
  loader as receiptLoader,
  action as receiptAction,
} from "./routes/Receipt";
import { UploadSuccess } from "./routes/UploadSuccess";
import {
  LineItemByName,
  loader as lineItemByNameLoader,
} from "./routes/LineItemByName";
import { ampli } from "./ampli";
import { getApiHost } from "./getApiHost";
import { LandingPage, action as landingPageAction } from "./routes/LandingPage";
import {
  ShoppingList,
  loader as shoppingListLoader,
} from "./routes/ShoppingList";
import {
  LinkBarcodeByLineItemId,
  loader as linkBarcodeByLineItemIdLoader,
} from "./routes/LinkBarcodeByLineItemId";
import {
  LinkBarcodeByLineItemName,
  loader as linkBarcodeByLineItemNameLoader,
} from "./routes/LinkBarcodeByLineItemName";
import { SearchByBarcode } from "./routes/SearchByBarcode";
import {
  LineItemByBarcode,
  loader as lineItemByBarcodeLoader,
  action as lineItemByBarcodeAction,
} from "./routes/LineItemByBarcode";
import { NextUIProvider } from "@nextui-org/react";
import { ErrorElement } from "./ErrorElement";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import {
  UnsubscribeEmail,
  action as unsubscribeEmailAction,
} from "./routes/UnsubscribeEmail";
import { Search, loader as searchLoader } from "./routes/Search";
import {
  PriceAlerts,
  loader as priceAlertsLoader,
  action as priceAlertsAction,
} from "./routes/PriceAlerts";
import { PriceAlert, loader as priceAlertLoader } from "./PriceAlert";
import { checkForVersionUpdates } from "./checkForVersionUpdates";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const AMPLITUDE_API_KEY =
  process.env.NODE_ENV === "production"
    ? "d73a718089d09fb92a12a4bc8a9f34d0"
    : "9c458f626ced0430de11079cff6d7dc5";

ampli
  .load({
    client: {
      apiKey: AMPLITUDE_API_KEY,
    },
  })
  .promise.then(() => {
    ampli.client.add(
      sessionReplayPlugin({
        sampleRate: 1,
      })
    );
  });

checkForVersionUpdates();
setInterval(() => {
  checkForVersionUpdates();
}, 1000 * 5);

const CustomRouterProvider = () => {
  const { user, login, logout } = useAuth();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/unsubscribe-email"
          element={<UnsubscribeEmail />}
          action={unsubscribeEmailAction}
        />
        <Route element={<UnAuthRoot />} errorElement={<ErrorElement />}>
          <Route
            path="/"
            element={<LandingPage />}
            action={landingPageAction(login)}
          />
          <Route
            path="/login"
            element={<LandingPage />}
            action={landingPageAction(login)}
          />
          <Route
            path="/register"
            element={<LandingPage />}
            action={landingPageAction(login)}
          />
        </Route>
        <Route element={<AuthRoot />} errorElement={<ErrorElement />}>
          <Route
            path="/home"
            element={<Home />}
            loader={homeLoader(user?.token)}
          />
          <Route
            path="/line-item-by-name/:lineItemName"
            element={<LineItemByName />}
            loader={lineItemByNameLoader(user?.token)}
          />
          <Route
            path="/line-item-by-barcode/:barcode"
            element={<LineItemByBarcode />}
            loader={lineItemByBarcodeLoader(user?.token)}
            action={lineItemByBarcodeAction(user?.token)}
          />
          <Route path="/upload-success" element={<UploadSuccess />} />
          <Route
            path="/receipts"
            element={<Receipts />}
            loader={receiptsLoader(user?.token)}
          />
          <Route
            path="/shopping-list"
            element={<ShoppingList />}
            loader={shoppingListLoader(user?.token)}
          />
          <Route
            path="/link-barcode-by-line-item-id/:lineItemId"
            element={<LinkBarcodeByLineItemId />}
            loader={linkBarcodeByLineItemIdLoader(user?.token)}
          />
          <Route
            path="/link-barcode-by-line-item-name/:lineItemName"
            element={<LinkBarcodeByLineItemName />}
            loader={linkBarcodeByLineItemNameLoader(user?.token)}
          />
          <Route
            path={"/search-by-barcode"}
            element={<SearchByBarcode token={user?.token} />}
          />
          <Route
            path="/profile"
            element={<Profile />}
            action={profileAction(logout)}
          />
          <Route
            path="/receipt/:receiptId"
            element={<Receipt />}
            loader={receiptLoader(user?.token)}
            action={receiptAction(user?.token)}
          />
          <Route
            path="/search"
            element={<Search />}
            loader={searchLoader(user?.token)}
          />
          <Route
            path="/price-alerts"
            element={<PriceAlerts />}
            loader={priceAlertsLoader(user?.token)}
            action={priceAlertsAction(user?.token)}
          />
          <Route
            path="/price-alert/:priceAlertId"
            element={<PriceAlert />}
            loader={priceAlertLoader(user?.token)}
          />
        </Route>
      </>
    )
  );

  return <RouterProvider router={router} />;
};

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider
      clientId={
        "1044799631360-jn94i16oc53ld2f7pcoafno6b3fe8ms4.apps.googleusercontent.com"
      }
    >
      <NextUIProvider className="h-full" locale="en-GB">
        <main className="dark text-foreground bg-background p-4 h-full">
          <AuthProvider>
            <CustomRouterProvider />
          </AuthProvider>
        </main>
        {/* <footer className="dark text-foreground bg-background p-4">
          <LegalFooter />
        </footer> */}
      </NextUIProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
