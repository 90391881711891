import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ampli } from "./ampli";
import { getApiHost } from "./getApiHost";
import { useAuth } from "./Auth";
import { CameraIcon } from "@heroicons/react/24/outline";

export function FloatingActionButton({ showArrow }: { showArrow: boolean }) {
  const { user } = useAuth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
    ampli.uploadReceiptClicked();
  };

  function upload(file: File) {
    const formData = new FormData();
    formData.append("receipt", file);
    setLoading(true);
    fetch(`${getApiHost()}/receipt/upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then(() => {
        ampli.uploadReceiptSuccess();
        setLoading(false);
        navigate("/upload-success");
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
        alert("Upload failed");
      });
  }

  return (
    <div className="fixed bottom-20 right-4 z-10">
      {showArrow && (
        <div className="absolute -top-8 right-4 animate-bounce">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 20L4 12L6 10L12 16L18 10L20 12L12 20Z"
              fill="currentColor"
            />
          </svg>
        </div>
      )}
      <button
        className="bg-primary text-white rounded-full p-4 shadow-lg"
        onClick={handleButtonClick}
      >
        {loading && (
          <div className="animate-spin">
            <svg
              className="size-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        )}
        {!loading && <CameraIcon className="size-6" />}
      </button>
      <input
        ref={fileInputRef}
        type="file"
        multiple={false}
        accept="image/*"
        style={{ display: "none" }}
        onChange={(e) => {
          const files = e.currentTarget.files;
          if (files === null) {
            return;
          }
          if (files.length === 0) {
            return;
          }
          const file = files[0];
          if (!file) {
            return;
          }
          upload(file);
        }}
      />
    </div>
  );
}
